
import {defineComponent, ref} from 'vue';
import Team from '@/models/Team';

export default defineComponent({
    props: {
        id: {
            type: String,
            required: true,
        },
    },
    setup(props) {
        return {
            hideError: ref<boolean>(false),
            team: ref(new Team({id: +props.id})),
        };
    },
    computed: {
        banner(): string|undefined {
            return this.team?.teamBanner;
        },
    },
    async created() {
        await this.team.fetch();

        this.hideError = true;
    },
});
