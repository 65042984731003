import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_RouterView = _resolveComponent("RouterView")!
  const _component_Portal = _resolveComponent("Portal")!
  const _component_WaitForResource = _resolveComponent("WaitForResource")!

  return (_openBlock(), _createBlock(_component_WaitForResource, {
    "hide-error": _ctx.hideError,
    resource: _ctx.team
  }, {
    default: _withCtx(() => [
      _createVNode(_component_RouterView, { "base-team": _ctx.team }, null, 8, ["base-team"]),
      (_ctx.banner)
        ? (_openBlock(), _createBlock(_component_Portal, {
            key: 0,
            to: "auth-banner"
          }, {
            default: _withCtx(() => [
              _createElementVNode("img", {
                class: "vh-100",
                src: _ctx.banner
              }, null, 8, _hoisted_1)
            ]),
            _: 1
          }))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }, 8, ["hide-error", "resource"]))
}